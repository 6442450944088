<template lang="pug">
	.main-wrapper.transacoes-visualizar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-3
					Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
				.p-col-9.ta-right
					h1.text-header.text-secondary MedClub /
						<router-link :to='{ name: "transacoes-financeiras" }'> Transações</router-link> /
						<b>Visualizar</b>

		BuscarPaciente(v-if='buscarPacienteToggle' ref='buscarPaciente' label='Pagante')

		Panel.my-2(header='Transação financeira')
			Imprimir(
				ref='boxImprimir'
				:id='id' :guias='guias'
				@setGuias='setGuias'
				@setMedclubEmpresa='setMedclubEmpresa'
				:estornoTotalPossivel='estornoTotalPossivel'
				@setEstornoTotalPossivel='setEstornoTotalPossivel')
			.box-actions.ta-center.my-2
				ProgressSpinner(v-if='waitingImprimirTermica' strokeWidth='6')
				div(v-else )
					//Button(label='Imprimir comprovante' icon='jam jam-ticket' @click='imprimirComprovante()')
					Button.p-button-success.ml-2(label='Gerar créditos' @click='cancelarGuias' :disabled='!guias' icon='jam jam-coin')
					Button.ml-2(label='Imprimir térmica' icon='jam jam-ticket' @click='imprimirTermica()')
					Button.ml-2(label='Imprimir guias' icon='jam jam-files' @click='imprimirTodasGuias()')
					Button.ml-2.p-button-warning(label='Adicionar estorno' :disabled='medclubEmpresa' icon='jam jam-plus' @click='adicionarEstorno')
					Button.ml-2.p-button-danger.p-button-raised.p-button-text( :disabled='estornoTotalPossivel' label='Estorno TEF' icon='pi custom-svg-icon-1' @click='estornoTotal')
					// Button.p-button-danger.ml-2(v-show='usuarioNivel >= 5 && habilitarEstorno'
						label='Estornar' icon='jam jam-minus-circle' @click="$router.push(`/transacoes-financeiras/estornar/${ id }/`)")
</template>

<style lang="scss">
	.transacoes-visualizar {
		.box-actions {
			.p-progress-spinner {
				height: 29px;
				width: 29px;
			}
		}
		.custom-svg-icon-1 {
			background: url("../../assets/img/estorno.svg");
			top: 9px;
			height: 23px;
			width: 23px;
			color: white
		}
		.buscar-paciente {
			.form-header {
				display: none;
			}
		}
	}
</style>

<script>
	import Panel from 'primevue/panel'
	import Button from 'primevue/button'
	import Imprimir from './Imprimir'
	import BuscarPaciente from './../Paciente/BuscarPaciente'
	import ProgressSpinner from 'primevue/progressspinner'
	import { Caixas } from '../../middleware'
	import EstornoIcon from '@/components/CustomComponents/icons/EstornoIcon'
	export default {
		data () {
			return {
				id: null,
				habilitarEstorno: false,
				usuarioNivel: 0,
				buscarPacienteToggle: false,
				waitingImprimirTermica: false,
				guias: 0,
				medclubEmpresa: false,
				estornoTotalPossivel: false,
			}
		},
		components: {
			Panel, Button, Imprimir, ProgressSpinner, BuscarPaciente, EstornoIcon
		},
		created () {
			if (isNaN(this.$route.params.id)) this.$router.go(-1)
			this.id = parseInt(this.$route.params.id)
			let usuario = JSON.parse(localStorage.getItem('usuario'))
			this.usuarioNivel = usuario.nivel
		},

		methods: {
			imprimirComprovante () {
				this.$refs.boxImprimir.imprimirComprovante()
			},
			imprimirTermica() {
				this.waitingImprimirTermica = true
				Caixas.findTransacaoPDF(this.id).then(response => {
					this.waitingImprimirTermica = false
					if (response.status == 200) {
						if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let blob = new Blob([response.data], { type: 'text/html; charset=utf-8' })
                            //var fileURL = URL.createObjectURL(blob);
                            //let a = window.open(fileURL);
                            //a.print()
                            var fd = new FormData();
                            //fd.append('fname', 'print.html');
                            fd.append('file', blob);
                            fetch('http://localhost:9990/imprimir', {
                                method: 'POST',
                                body: fd
                            })

                        }
                    }
				})
			},
			imprimirTodasGuias () {
				this.$refs.boxImprimir.openDialogImpressao()
			},
			adicionarEstorno () {
				this.$refs.boxImprimir.openEstornoDialog()
			},
			cancelarGuias () {
				this.$refs.boxImprimir.openDialogCancelar()
			},
			estornoTotal() {
				this.$refs.boxImprimir.openDialogEstornoTotal()
			},
			setGuias(val) {
				this.guias = val
			},
			setMedclubEmpresa(val) {
				this.medclubEmpresa = val
			},
			setEstornoTotalPossivel(val) {
				this.estornoTotalPossivel = val
			},
		}
	}
</script>
